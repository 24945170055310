import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { DfTaskListTmplS } from '../../../Task/List/S/Tmpl/module/DfTaskListTmplS.js';
import { makeDfProjectionListFilterHeader } from '../S/tmpl.js';
import { cjTrackingFromShippingId, makeDfProjectionListEvent } from './event.js';
import { makeDfProjectionItemsHtml } from './tmpl.js';
import { compact, pipe, strMap, tap, html, go } from 'fxjs/es';
import { downloadForPdLand } from '../../../../Maker/F/HalfAutoTemplate/fs.js';
import { moveScrollToFirstPrj } from './frame.js';
import { $attr, $delegate, $closest, $find } from 'fxdom/es';
import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { printShopChildrenWaybills } from '../../../Waybill/F/Function/print_shop_children_waybills.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { printMpsUpsForNESS } from './ness.js';
import axios from 'axios';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { DfLglHaezleF } from '../../../Lgl/Haezle/F/Function/module/DfLglHaezleF.js';
import format from 'date-fns/format/index.js';

const projectionTitleH1 = (states) => {
  if (states.promotion_code_id) {
    return html`<h1 class="shinhan">신한${states.promotion_code_not ? '이 아닌' : ''} 주문서</h1>`;
  } else {
    return html`<h1 class="${states.status}">${states.is_line ? '라인프렌즈 팩토리 ' : ''}주문서</h1>`;
  }
};
export const defnDfProjectionListTab = () => ({
  tab_name: 'projection.list',
  template: pipe(window.box, ({ projections, states, state_data, states: { product_mode = 'up_list' } }) => {
    return html`
      <div class="projection_list_container" _sel="projections" prj_product_mode="${product_mode}">
        <div id="filter">
          <div class="title">${projectionTitleH1(states)}</div>
          <div class="search_bar">
            <form action="/projection/list" id="search_bar" method="get">
              <input type="hidden" name="status" value="${states.status}" class="query" />
              ${makeDfProjectionListFilterHeader(states)}
              ${states.status == 'refund_cancel'
                ? html`
                    <div class="search_cancel sd query radio" name="cancel_refund">
                      <div class="s_col title">취소관련</div>
                      <div class="s_col">
                        <label>
                          <input type="radio" value="all" name="cancel_refund" checked />
                          <span>전체</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="refund_req"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'refund_req' ? 'checked' : ''}
                          />
                          <span>부분 환불 요청만</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="refunded"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'refunded' ? 'checked' : ''}
                          />
                          <span>부분 환불 완료만</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="cancel_req"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'cancel_req' ? 'checked' : ''}
                          />
                          <span>취소 요청만</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="cancel_req_refund_req"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'cancel_req_refund_req' ? 'checked' : ''}
                          />
                          <span>취소 요청 중 전액 환불 요청만</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="cancel_req_refunded"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'cancel_req_refunded' ? 'checked' : ''}
                          />
                          <span>취소 요청 중 환불 완료만</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="canceled"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'canceled' ? 'checked' : ''}
                          />
                          <span>취소 완료</span>
                        </label>
                      </div>
                      <div class="s_col">
                        <label>
                          <input
                            value="canceled_refunded"
                            type="radio"
                            name="cancel_refund"
                            ${states.cancel_refund == 'canceled_refunded' ? 'checked' : ''}
                          />
                          <span>취소 완료 중 환불 완료만</span>
                        </label>
                      </div>
                    </div>
                  `
                : ``}
              <div class="search_date sd">
                <div class="s_col title">
                  ${(function (states) {
                    if (['before_print', 'printing', 'before_deliver', 'ing'].includes(states.status))
                      return '출고일';
                    if (states.status == 'delivering') return '배송일';
                    if (states.status == 'refund_cancel') {
                      if (states.cancel_refund == 'refund_req') return '환불 요청일';
                      if (states.cancel_refund == 'refunded') return '환불 완료일';
                      if (states.cancel_refund == 'cancel_req') return '취소 요청일';
                      if (states.cancel_refund == 'cancel_req_refund_req') return '환불 요청일';
                      if (states.cancel_refund == 'cancel_req_refunded') return '환불 완료일';
                      if (states.cancel_refund == 'canceled') return '취소 완료일';
                      if (states.cancel_refund == 'canceled_refunded') return '취소 완료일';
                    }
                    return '주문일';
                  })(states)} 기준
                </div>
                <div class="s_col search_order_by query radio" name="order_by">
                  <div class="s_col">
                    <label>
                      <input
                        value="ASC"
                        type="radio"
                        name="order_by"
                        ${states.order_by == 'ASC' ? 'checked' : ''}
                      />
                      <span>오름차순</span>
                    </label>
                  </div>
                  <div class="s_col">
                    <label>
                      <input
                        value="DESC"
                        type="radio"
                        name="order_by"
                        ${states.order_by == 'DESC' ? 'checked' : ''}
                      />
                      <span>내림차순</span>
                    </label>
                  </div>
                </div>
                <div class="s_col">
                  <div class="s_col title">기간 설정</div>
                  <div class="s_col">
                    <input
                      type="hidden"
                      name="at_opt"
                      value="${(function (states) {
                        if (['before_print', 'printing', 'before_deliver', 'ing'].includes(states.status))
                          return 'due';
                        if (states.status == 'delivering') return 'delivering';
                        if (states.status == 'refund_cancel') {
                          if (states.cancel_refund == 'refund_req') return 'refund_requested';
                          if (states.cancel_refund == 'refunded') return 'refunded';
                          if (states.cancel_refund == 'cancel_req') return 'cancel_requested';
                          if (states.cancel_refund == 'cancel_req_refund_req') return 'refund_requested';
                          if (states.cancel_refund == 'cancel_req_refunded') return 'refunded';
                          if (states.cancel_refund == 'canceled') return 'canceled';
                          if (states.cancel_refund == 'canceled_refunded') return 'canceled';
                        }
                        return 'ordered';
                      })(states)}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      placeholder="날짜를 선택해 주세요."
                      type="text"
                      name="s_at"
                      value="${states.s_at}"
                      class="query due_at"
                    />
                    <span>~</span>
                    <input
                      placeholder="날짜를 선택해 주세요."
                      type="text"
                      name="e_at"
                      value="${states.e_at}"
                      class="query due_at"
                    />
                  </div>
                </div>
              </div>
              <div class="sd">
                <div class="s_col title">사내몰</div>
                <div class="s_col">
                  <label>
                    <input
                      value="true"
                      type="checkbox"
                      name="is_inhouse"
                      ${states.is_inhouse ? 'checked' : ''}
                      class="query"
                    />
                    <span>사내배송</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <select name="crew_inhouse_shipping_id" class="query">
                      <option value="" ${states.crew_inhouse_shipping_id == null ? 'selected' : ''}>
                        모든 사내배송
                      </option>
                      <option
                        value="private_only"
                        ${states.crew_inhouse_shipping_id === 'private_only' ? 'selected' : ''}
                      >
                        개인 배송만
                      </option>
                      <option
                        value="company_only"
                        ${states.crew_inhouse_shipping_id === 'company_only' ? 'selected' : ''}
                      >
                        사내 배송만
                      </option>
                      ${strMap((s) => {
                        return html`<option
                          value="${s.id}"
                          ${parseInt(states.crew_inhouse_shipping_id) === s.id ? 'selected' : ''}
                        >
                          ${s.company_label} - ${s.location_name} - ${s.company_address}
                        </option>`;
                      }, box.sel('crew_inhouse_shippings'))}
                    </select>
                  </label>
                </div>
              </div>
              <div class="sd">
                <div class="s_col title">마플샵 검색</div>
                <div class="s_col">
                  <input
                    type="text"
                    name="store_name"
                    placeholder="스토어 이름"
                    value="${states.store_name || ''}"
                    class="query"
                  />
                </div>
                <div class="s_col title">마플샵상품</div>
                <div class="s_col">
                  <input
                    type="text"
                    name="product_name"
                    placeholder="마플샵 상품 이름"
                    value="${states.product_name || ''}"
                    class="query"
                  />
                </div>
                <div class="s_col query">
                  <select name="product_name_only" class="query">
                    <option value="">포함(기본)</option>
                    <option value="only" ${states.product_name_only == 'only' ? 'selected' : ''}>
                      만 있는
                    </option>
                    <option value="not" ${states.product_name_only == 'not' ? 'selected' : ''}>
                      가 없는
                    </option>
                  </select>
                </div>
                <div class="s_col title">샵프리카 주문번호</div>
                <div class="s_col">
                  <input
                    style="width: 300px;"
                    type="text"
                    name="afreeca_no"
                    placeholder="샵프리카 주문번호/상품주문번호/옵션주문번호"
                    value="${states.afreeca_no || ''}"
                    class="query"
                  />
                </div>
              </div>
              <div class="search_text sd">
                <div class="s_col title">주문서 검색</div>
                <div class="s_col">
                  <div class="s_col">
                    <input
                      type="text"
                      name="p_id"
                      placeholder="주문서 ID"
                      value="${states.p_id}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="merged_parent_ids"
                      placeholder="병합주문서 ID"
                      value="${states.merged_parent_ids}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="o_p_id"
                      placeholder="구 주문서 ID"
                      value="${states.o_p_id}"
                      class="query"
                    />
                  </div>
                  <div class="s_col title">주문수량</div>
                  <div class="s_col">
                    <input
                      type="number"
                      name="prj_quantity"
                      placeholder="주문수량"
                      value="${states.prj_quantity || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col query " name="is_deliver">
                    <div class="s_col title" style="margin-right: 4px;">재제작</div>
                    <div class="s_col">
                      <label>
                        <input
                          value="true"
                          type="checkbox"
                          name="is_repress"
                          class="query"
                          ${states.is_repress ? 'checked' : ''}
                        />
                        <span>재제작만</span>
                      </label>
                    </div>
                    <div class="s_col title" style="margin-right: 4px;">취소여부</div>
                    <div class="s_col">
                      <label>
                        <select name="is_canceled" class="query">
                          <option value="" ${states.is_canceled == null ? 'selected' : ''}>
                            상관없음 (전부)
                          </option>
                          <option value="false" ${states.is_canceled === false ? 'selected' : ''}>
                            취소 제외
                          </option>
                          <option value="true" ${states.is_canceled === true ? 'selected' : ''}>
                            취소 만
                          </option>
                        </select>
                      </label>
                    </div>
                    <div class="s_col title" style="margin-right: 4px;">고객요청</div>
                    <div class="s_col">
                      <label>
                        <label>
                          <input
                            value="true"
                            type="checkbox"
                            name="special_request"
                            class="query"
                            ${states.special_request ? 'checked' : ''}
                          />
                          <span>고객요청만</span>
                        </label>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="search_text sd">
                <div class="s_col title">고객 검색</div>
                <div class="s_col">
                  <div class="s_col">
                    <input
                      type="text"
                      name="user_name"
                      placeholder="이름"
                      value="${states.user_name || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="user_mobile"
                      placeholder="전화번호"
                      value="${states.user_mobile || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="user_email"
                      placeholder="이메일"
                      value="${states.user_email || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="auth_uid"
                      placeholder="고객 UID (영어,숫자)"
                      value="${states.auth_uid || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="number"
                      name="user_id"
                      placeholder="고객 ID (숫자)"
                      value="${states.user_id || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="waybill_number"
                      placeholder="운송장"
                      value="${states.waybill_number || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="promotion_code"
                      placeholder="프로모션코드"
                      value="${states.promotion_code || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="number"
                      name="total_price"
                      placeholder="주문금액"
                      value="${states.total_price || ''}"
                      class="query"
                    />
                  </div>
                </div>
              </div>
              <div class="search_text sd">
                <div class="s_col title">기본 상품 검색</div>
                <div class="s_col">
                  <div class="s_col">
                    <input
                      type="text"
                      name="bp_name_info"
                      placeholder="기본 상품 이름"
                      value="${states.bp_name_info || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">
                    <input
                      type="text"
                      name="bp_color_info"
                      placeholder="기본 상품 색상"
                      value="${states.bp_color_info || ''}"
                      class="query"
                    />
                  </div>
                </div>
                ${DfTaskListTmplS.productTypeInfo(states)}
              </div>
              <div class="sd">
                <div class="s_col title">배송 방식</div>
                <div class="s_col">
                  <div class="s_col">
                    <label
                      ><input
                        class="query arr"
                        type="checkbox"
                        name="shipping_types"
                        value="parcel"
                        ${states.shipping_types?.indexOf('parcel') > -1 ? 'checked' : ''}
                      /><span>택배</span></label
                    >
                  </div>
                  <div class="s_col">
                    <label
                      >[<input
                        class="query arr"
                        type="checkbox"
                        name="shipping_types"
                        value="combined"
                        ${states.shipping_types?.indexOf('combined') > -1 ? 'checked' : ''}
                      /><span>묶음배송</span></label
                    >
                  </div>
                  <div class="s_col">
                    <label
                      >-<input
                        class="query arr"
                        type="checkbox"
                        name="only_parent"
                        value="true"
                        ${states.only_parent > -1 ? 'checked' : ''}
                      /><span>(부모주문서만)]</span></label
                    >
                  </div>
                  <div class="s_col">
                    <label
                      ><input
                        class="query arr"
                        type="checkbox"
                        name="shipping_types"
                        value="partition"
                        ${states.shipping_types?.indexOf('partition') > -1 ? 'checked' : ''}
                      /><span>개별배송</span></label
                    >
                  </div>
                  <div class="s_col">
                    <label
                      ><input
                        class="query arr"
                        type="checkbox"
                        name="shipping_types"
                        value="quick"
                        ${states.shipping_types?.indexOf('quick') > -1 ? 'checked' : ''}
                      /><span>퀵</span></label
                    >
                  </div>
                  <div class="s_col">
                    <label
                      ><input
                        class="query arr"
                        type="checkbox"
                        name="shipping_types"
                        value="visit"
                        ${states.shipping_types?.indexOf('visit') > -1 ? 'checked' : ''}
                      /><span>방문</span></label
                    >
                  </div>
                </div>
                <div class="s_col title" value="${states.shipping_company_id}">택배사</div>
                <div class="s_col">
                  <label>
                    <select name="shipping_company_id" class="query">
                      <option value="">선택안함</option>
                      ${strMap(
                        ({ id, name }) => html`
                          <option value="${id}" ${states.shipping_company_id == id ? 'selected' : ''}>
                            ${name}
                          </option>
                        `,
                        box.sel('shipping_companies'),
                      )}
                    </select>
                  </label>
                </div>
                <div class="s_col title" style="margin-right: 4px;">배송여부</div>
                <select name="is_deliver" class="query">
                  <option value="">해당없음</option>
                  <option value="on" ${states.is_deliver === 'on' ? 'selected' : ''}>배송된</option>
                  <option value="off" ${states.is_deliver === 'off' ? 'selected' : ''}>배송 안된</option>
                </select>
              </div>
              <div class="sd">
                <div class="s_col title" value="${states.pay_method}">결제</div>
                <div class="s_col">
                  <select name="pay_method" class="query">
                    <option value="">결제방식선택</option>
                    <option value="without_bank" ${states.pay_method == 'without_bank' ? 'selected' : ''}>
                      무통장입금
                    </option>
                    <option value="card" ${states.pay_method == 'card' ? 'selected' : ''}>카드</option>
                    <option value="samsung" ${states.pay_method == 'samsung' ? 'selected' : ''}>
                      삼성페이
                    </option>
                    <option value="naverpay" ${states.pay_method == 'naverpay' ? 'selected' : ''}>
                      네이버페이
                    </option>
                    <option value="tosspay" ${states.pay_method == 'tosspay' ? 'selected' : ''}>
                      토스페이
                    </option>
                    <option value="kakaopay" ${states.pay_method == 'kakaopay' ? 'selected' : ''}>
                      카카오페이
                    </option>
                    <option value="payco" ${states.pay_method == 'payco' ? 'selected' : ''}>PAYCO</option>
                    <option value="ssgpay" ${states.pay_method == 'ssgpay' ? 'selected' : ''}>SSGPAY</option>
                    <option value="vbank" ${states.pay_method == 'vbank' ? 'selected' : ''}>가상계좌</option>
                    <option value="trans" ${states.pay_method == 'trans' ? 'selected' : ''}>
                      실시간계좌이체
                    </option>
                    <option
                      value="PAYMENTWALL_CREDIT_CARD"
                      ${states.pay_method == 'PAYMENTWALL_CREDIT_CARD' ? 'selected' : ''}
                    >
                      Credit Card
                    </option>
                    <option
                      value="VBANK_STYLE_PAY_METHODS"
                      ${states.pay_method === 'VBANK_STYLE_PAY_METHODS' ? 'selected' : ''}
                    >
                      일본 은행 및 편의점
                    </option>
                    <option value="alipay" ${states.pay_method == 'alipay' ? 'selected' : ''}>
                      알리페이
                    </option>
                    <option value="paypal" ${states.pay_method == 'paypal' ? 'selected' : ''}>페이팔</option>
                    <option value="free" ${states.pay_method == 'free' ? 'selected' : ''}>
                      쿠폰/포인트 전액결제
                    </option>
                    <option value="offline_card" ${states.pay_method == 'offline_card' ? 'selected' : ''}>
                      오프라인카드
                    </option>
                    <option value="offline_cash" ${states.pay_method == 'offline_cash' ? 'selected' : ''}>
                      오프라인현금
                    </option>
                  </select>
                </div>
                <div class="s_col">
                  <label>
                    <span class="s_col title" style="margin-right: 4px;">미수금이 있는 주문</span>
                    <input type="checkbox" name="amount" ${states.amount ? 'checked' : ''} class="query" />
                  </label>
                </div>
                <div class="s_col title">
                  주문금액 이외로 검색
                  <div class="s_col">
                    <input
                      type="number"
                      name="price"
                      placeholder="가격"
                      value="${states.price || ''}"
                      class="query"
                    />
                  </div>
                  <div class="s_col">-</div>
                  <div class="s_col">
                    포인트사용, 쿠폰사용, 추가금, 추가배송비, 결제한금액, 미수금, 환불한 총액 등 ...
                  </div>
                  <div style="margin-top: 8px;"></div>
                </div>
              </div>
              <div class="sd">
                <div class="s_col title">얼마 이상</div>
                <div class="s_col">
                  <input
                    type="number"
                    name="more_price"
                    placeholder="몇 원 이상"
                    value="${states.more_price}"
                    class="query"
                  />
                </div>
                <div class="s_col">
                  <input
                    type="number"
                    name="more_price_en"
                    placeholder="몇 달러 이상"
                    value="${states.more_price_en}"
                    class="query"
                  />
                </div>
                <div class="s_col">
                  <input
                    type="number"
                    name="more_price_jp"
                    placeholder="몇 엔 이상"
                    value="${states.more_price_jp}"
                    class="query"
                  />
                </div>
                <div class="s_col">
                  <input type="number" name="more_quantity" placeholder="몇 개 이상
                  value="${states.more_quantity}" class="query" />
                </div>
              </div>
              ${states.status == 'delivering'
                ? html`
                    <div class="search_today_delivering sd">
                      <div class="s_col title">오늘 출고 예정</div>
                      <div class="s_col">
                        <label>
                          <input
                            type="checkbox"
                            name="today_delivering"
                            value="${states.today_delivering}"
                            ${states.today_delivering ? 'checked' : ''}
                            class="query"
                          />
                        </label>
                      </div>
                    </div>
                  `
                : ''}
              ${['before_print', 'printing', 'before_deliver', 'ing'].includes(states.status)
                ? html`
                    <div class="search_due_at important sd">
                      <div class="tr">
                        <div class="s_col title">긴급 출고일 (주문수, 상품수)</div>
                        <div class="s_col dates">
                          ${G.df.search9(state_data.imp_prj_dates, 'imp_prj', true)}
                        </div>
                      </div>
                    </div>
                    <div class="search_due_at sd">
                      <div class="tr">
                        <div class="s_col title">모든 출고일 (주문수, 상품수)</div>
                        <div class="s_col dates">${G.df.search9(state_data.prj_dates, 'prj', true)}</div>
                      </div>
                    </div>
                  `
                : ''}
              <div class="search_result search_btn">
                <button class="init" type="button">초기화</button>
                <button class="go" type="submit">검색</button>
              </div>
            </form>
          </div>
        </div>
        <div class="prj_task_pagination">${G.df.pagination(states)}</div>
        <div id="mode_selector">
          <select name="product_mode" class="prj_product_mode prj_list query">
            <option value="up_list" ${product_mode == 'up_list' ? 'selected' : ''}>기본 모드</option>
            <option value="payment" ${product_mode == 'payment' ? 'selected' : ''}>결제 관리 모드</option>
            <option value="bp_stocks" ${product_mode == 'bp_stocks' ? 'selected' : ''}>
              상품 / 재고 모드
            </option>
          </select>
        </div>
        <div class="df_grid prj projection_list">${makeDfProjectionItemsHtml(projections)}</div>
        <div class="prj_task_pagination">${G.df.pagination(states)}</div>
        <div style="width:100%;height:20px"></div>
      </div>
    `;
  }),
  appending: function (el_don_tab) {
    return G.df.projection.detail.img_thumbnail_error(el_don_tab);
  },
  appended: pipe(
    $.on(
      'click',
      '.search_result button.init',
      () => (window.location.href = window.location.pathname + `?status=${box.sel('states->status')}`),
    ),
    downloadForPdLand,
    $.on('click', '.search_result button.go', G.df.search_btn_event),
    tap(function (el_don_tab) {
      const marker = new Mark('#body .projection_list_container .df_grid.prj');
      el_don_tab.marker = marker;
      marker.mark(
        compact([
          $.val($1('#filter input[name="store_name"]')) || '',
          $.val($1('#filter input[name="product_name"]')) || '',
          $.val($1('#filter input[name="address"]')) || '',
          $.val($1('#filter input[name="p_id"]')) || '',
          $.val($1('#filter input[name="o_p_id"]')) || '',
          $.val($1('#filter input[name="user_name"]')) || '',
          $.val($1('#filter input[name="user_mobile"]')) || '',
          $.val($1('#filter input[name="user_email"]')) || '',
          $.val($1('#filter input[name="user_id"]')) || '',
          $.val($1('#filter input[name="store_name"]')) || '',
          $.val($1('#filter input[name="shipping_user_info"]')) || '',
          $.val($1('#filter input[name="bp_name_info"]')) || '',
          $.val($1('#filter input[name="bp_color_info"]')) || '',
          $.val($1('#filter input[name="waybill_number"]')) || '',
          $.val($1('#filter input[name="price"]'))
            ? PriceS.pricify($.val($1('#filter input[name="price"]')))
            : '',
        ]).join(' '),
        { separateWordSearch: true },
      );
    }),
    G.mp.maker.draw_product_face_in_ups,
    tap((el_don_tab) => G.df.projection.detail.up_item_init(el_don_tab)),
    makeDfProjectionListEvent,
    G.df.task.projection.init,
  ),
  rendered: (tab_el) => {
    go(
      tab_el,
      $delegate('click', '.shop-waybill-status[is_creator="false"]', async ({ currentTarget: ct }) => {
        const shipping_id = box.sel(ct)._.shippings[0].id;
        await cjTrackingFromShippingId({ shipping_id });
      }),
      $delegate('click', '.shop-waybill-status.merged_parent', async (e) => {
        const el = go(e.currentTarget, $closest('.projection_item'));
        const prj_id_data = $attr('_sel', el);
        const id_matcher = prj_id_data.match(/\d+/g);
        if (id_matcher != null && id_matcher.length) {
          const projection_id = id_matcher[0];
          await DfWaybillF.popupChildShippingStatusForShopByParent({ projection_id });
        }
      }),
      $delegate('click', '.shop-waybill-status.merged_child', async (e) => {
        const el = go(e.currentTarget, $closest('.projection_item'));
        const prj_id_data = $attr('_sel', el);
        const id_matcher = prj_id_data.match(/\d+/g);
        if (id_matcher != null && id_matcher.length) {
          const projection_id = id_matcher[0];
          await DfWaybillF.popupChildShippingStatusForShopByChild({ projection_id });
        }
      }),
      $delegate('click', '.save_lgl_express_for_merged_parent', async ({ currentTarget: ct }) => {
        const merged_parent_projection = go(ct, $closest('.projection_item'), box.sel);

        try {
          $.don_loader_start();
          const child_projections_oversea = (
            await axios.get(
              UtilS.makeApiUrl('/@api/projections/:parent_id/childs/oversea_express', {
                parent_id: merged_parent_projection.id,
              }),
            )
          ).data;

          if (UtilS.isEmpty(child_projections_oversea)) {
            $.don_loader_end();
            $.alert('해외 배송용 주문서가 없습니다.');
            return;
          }

          const {
            _: { store },
          } = merged_parent_projection;

          await DfLglHaezleF.bulkDownloadLglHaezleForm({
            filename: `병합주문_${store.name}_${merged_parent_projection.id}_${format(
              new Date(),
              'yyyy_MM_dd',
            )}`,
            projections: child_projections_oversea,
          });
          $.don_loader_end();
        } catch (err) {
          console.error(err);
          $.don_loader_end();
          $.alert(UtilF.getErrMsg(err));
        }
      }),
      $delegate('click', '.print_children_waybills', async ({ currentTarget: ct }) => {
        const projection_item_el = go(ct, $closest('.projection_item '));
        const is_inhouse = $attr('is_inhouse', projection_item_el);

        if (is_inhouse === 'true') {
          $.alert('사내 배송은 개별건에 대해 운송장 출력을 하지 않습니다.');
          return;
        }

        const parent_projection_id =
          box.sel(ct)?.id || go(projection_item_el, $find('.projection_code_image'))?.dataset?.projection_id;

        if (parent_projection_id == null) {
          $.alert('주문 번호를 가져올 수 없습니다.');
          return;
        }
        try {
          await printShopChildrenWaybills({ parent_projection_id });
          DfInhouseF.lottie_loader.end();
        } catch (err) {
          console.error(err);
          DfInhouseF.lottie_loader.end();
          $.alert(err.message);
        }
      }),
      $delegate('click', '.print_hanjin_inbound_orders_for_pod', async ({ currentTarget: ct }) => {
        DfInhouseF.lottie_loader.start();
        try {
          const projection = box.sel(ct);
          await printMpsUpsForNESS({ projection_id: projection.id });
          DfInhouseF.lottie_loader.end();
          await UtilAlertF.success({
            title: 'POD 한진 입고',
            msg: '한진 입고 후 반드시 한진입고 태스크를 완료해 주세요.',
            timer: 0,
          });
        } catch (err) {
          console.error(err);
          DfInhouseF.lottie_loader.end();

          await UtilAlertF.error({
            title: '오류 발생',
            msg: err.isAxiosError ? err.response.data : err?.message ?? '개발팀 문의',
          });
        }
      }),
    );
    moveScrollToFirstPrj(tab_el);
  },
});
